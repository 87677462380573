/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

.nav-item.dropdown .dropdown-menu {
    display: none;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}
